<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('settings.services')"
      :sub-title="$t('settings.services_subtitle')"
      btn
      variant="main"
      :btn-title="$t('common.add_new_service')"
      btn-prepend-icon="add-circle-icon"
      classes="text-bold-15 text-white w-cus-sm-100"
      @btnAction="addServicesModal = true"
    />
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        mt-md-0 mt-4
      "
    >
      <div class="d-flex flex-wrap flex-1 px-0">
        <b-col lg="12" md="12" class="px-0">
          <wameed-no-data
            v-if="getAllServices && getAllServices.length < 1"
            icon="nodata-icon"
            :title="$t('offers_page.no_data')"
            :sub-title="$t('offers_page.no_data_text')"
          />
        </b-col>
        <b-col
          v-for="(item, index) in getAllServices"
          :key="index"
          lg="3"
          md="3"
          class="py-3"
        >
          <b-card
            no-body
            class="cursor-pointer wameed-card h-100 m-0"
            @click="serviceDetails(item.id)"
          >
            <b-card-header class="flex-nowrap align-items-start py-0">
              <b-media no-body class="flex-wrap w-100">
                <b-media-body>
                  <b-row
                    class="package-details-table__body"
                    style="background-color: inherit"
                  >
                    <b-col cols="1" class="p-0 m-0">
                      <div
                        class="package-details-table__body px-0"
                        style="background-color: inherit"
                      >
                        <check-box-offer-icon
                          style="width: 20px; height: 20px"
                        />
                      </div>
                    </b-col>
                    <b-col cols="11" class="p-0 m-0 px-4">
                      <b-row
                        class="package-details-table__body p-0"
                        style="background-color: inherit"
                      >
                        <b-col cols="12" class="package-details-value px-0">
                          <p class="mb-0">
                            {{ item.name_ar }}
                          </p>
                        </b-col>
                        <b-col cols="12" class="package-details-name px-0">
                          <p class="mb-0">
                            {{ item.name_en }}
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-media-body>
              </b-media>
            </b-card-header>
          </b-card>
        </b-col>
      </div>

      <b-col
        v-if="getAllServices && getAllServices.length > 0"
        lg="12"
        md="12"
        class="px-0"
      >
        <wameed-pagination
          v-model="filterData.page"
          :page="filterData.page"
          :total-items="getTotalServices.totalItems"
          :per_page="filterData.per_page"
          @changePage="changePage"
          @changeCurrentPage="changeCurrentPage"
        />
      </b-col>
    </section>
    <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      style="overflow: visible"
    >
      <b-modal
        id="wameed-modal"
        v-model="addServicesModal"
        dialog-class="max-width-600"
        header-class="py-5 px-5"
        body-class="py-5 px-5"
        title=""
        no-fade
        hide-header
      >
        <div class="align-items-center w-100">
          <div
            class="
              d-flex
              flex-column
              justify-content-between
              align-items-center
              w-75
              mx-auto
              mt-5
            "
          >
            <b-avatar :variant="'light-main'" rounded size="76" class="mb-5">
              <services-icon />
            </b-avatar>

            <h4 class="text-med-20 py-0 text-dark">
              {{ $t('common.add_new_service') }}
            </h4>
            <h4 class="text-reg-16 py-0 text-center text-font-secondary">
              {{ $t('common.add_new_service_subtitle') }}
            </h4>
          </div>
        </div>
        <wameed-form refs="addOfferForm">
          <template slot="form-content" slot-scope="{ invalid }">
            <b-row class="g-0">
              <b-col cols="12">
                <label class="text-bold-14 text-font-main required">{{
                  $t('common.service_name')
                }}</label>
              </b-col>
              <b-col md="6">
                <div class="p-0">
                  <text-input
                    id="name_ar"
                    v-model="submitData.name_ar"
                    :placeholder="$t('common.enter_ar_name')"
                    input-classes="text-bold-14 text-font-main"
                    name="name_ar"
                    is-append
                    :append-text="'ع'"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <div class="p-0">
                  <text-input
                    id="name_en"
                    v-model="submitData.name_en"
                    :placeholder="$t('common.enter_en_name')"
                    input-classes="text-bold-14 text-font-main"
                    name="name_en"
                    is-append
                    :append-text="'E'"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
        </wameed-form>
        <template slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center w-100">
            <wameed-btn
              classes="  text-med-14 text-white rounded-10"
              :title="$t('orders_page.add_on')"
              :disabled="submitData.name_en === '' || submitData.name_ar === ''"
              type="submit"
              variant="main"
              @submitAction="addNewSector"
            />
            <wameed-btn
              classes="  text-med-14 text-font-secondary rounded-10 "
              :title="$t('common.cancel')"
              type="button"
              variant="gray"
              @submitAction="hideAddServicesModal()"
            />
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import OrdersCard from '@/components/pages/orders/OrdersCard.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';
export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WarningModal,
    ValidationObserver,
    WameedForm,
    WameedBtn,
    TextInput,
  },
  data() {
    return {
      filterData: {
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      addServicesModal: false,
      submitData: {
        name_ar: '',
        name_en: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllServices: 'getAllServices',
      getTotalServices: 'getTotalServices',
    }),
  },
  watch: {},
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadServices: 'loadServices',
      addService: 'addService',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadServices(this.filterData);
    },
    hideAddServicesModal() {
      this.addServicesModal = false;
      this.submitData = {
        name_ar: null,
        name_en: null,
      };
    },
    addNewSector() {
      this.addService({
        name_ar: this.submitData.name_ar,
        name_en: this.submitData.name_en,
      }).then(() => {
        this.addServicesModal = false;
        this.submitData = {
          name_ar: null,
          name_en: null,
        };
        this.loadData();
      });
    },
    serviceDetails(id) {
      this.$router.push({
        name: 'service-details',
        params: {
          lang: this.$i18n.locale,
          id,
        },
      });
    },
  },
};
</script>
